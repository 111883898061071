<template>
  <div class="card card-custom">
    <div class="card-header">
      <h6 class="card-title">
        {{ status.processName }} {{ status.user }} завершил {{ status.time }} за
        {{ Math.round(timeSpent / 60000) }} мин
      </h6>
      <p></p>
    </div>
    <div class="card-body p-0">
      <b-list-group>
        <b-list-group-item
          v-for="answer in checklist"
          :key="answer.id"
          class="d-flex justify-content-between align-items-center"
        >
          <div>
            <b-badge variant="success" pill>{{ answer.task.type }}</b-badge>
            {{ answer.task.name }}:
          </div>
          <b-badge variant="primary" pill v-if="answer.task.type === 'Дата'">{{
            parseTime(answer.answer, "dd.mm.yyyy")
          }}</b-badge>
          <template v-else-if="answer.task.type === 'Множественный выбор'"
            ><b-badge
              variant="primary"
              pill
              v-for="qa in answer.answers"
              :key="qa"
              >{{ qa }}</b-badge
            ></template
          >
          <img
            v-else-if="
              answer.task.type === 'Графический ответ' ||
                answer.task.type === 'Фото'
            "
            :src="drawImage(answer.answer)"
            style="max-width:50%;"
          />
          <template v-else-if="answer.task.type === 'Сотрудник'">
            <b-badge
              variant="primary"
              pill
              v-for="qa in answer.answers"
              :key="qa.id"
              >{{ qa.first_name }} {{ qa.last_name }} ({{
                qa.username
              }})</b-badge
            >
          </template>
          <b-badge variant="primary" pill v-else>{{ answer.answer }}</b-badge>
        </b-list-group-item>
      </b-list-group>
    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-between border-top pt-10">
        <div>
          <button
            @click="goBack"
            class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
            data-wizard-type="action-submit"
          >
            Назад
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";

//import Inspection from "./components/Inspection";

export default {
  name: "checklist-answer",
  components: {
    //Inspection,
  },
  props: {
    status: Object
  },
  data() {
    var d = new Date();
    var month = "" + (d.getMonth() + 1);
    var day = "" + d.getDate();
    var year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    var today = [year, month, day].join("-");
    return {
      checklist: [],
      today: today,
      timeSpent: ""
    };
  },

  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },

  created() {
    if (KTUtil.isEmpty(this.status)) {
      Swal.fire({
        title: "",
        text: "Пожалуйста, выберите завершенный тест еще раз!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      }).then(() => {
        this.$router.push("/dashboard");
      });
    } else {
      this.loadAnswers();
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Ответы" },
      { title: "Тесты" }
    ]);
  },

  methods: {
    drawImage(src) {
      if (!src.includes(this.$host)) {
        src = this.$host + src;
      }
      return src;
    },

    async loadAnswers() {
      try {
        return new Promise(resolve => {
          ApiService.post("api/v1/ra2/", {
            status_id: this.status.id
          }).then(({ data }) => {
            //console.log(data);
            this.timeSpent =
              new Date(data.date_end) - new Date(data.date_begin);
            data.answers.sort((a, b) => a.id - b.id);
            this.checklist = data.answers;
            resolve(data);
          });
        });
      } catch ({ response }) {
        console.error(response);
      }
    },

    goBack: function() {
      //this.$router.push('/dashboard');
      this.$router.go(-1);
    },

    parseTime(datetime, format = "dd.mm") {
      var newdate = new Date(datetime);
      var dd = String(newdate.getDate()).padStart(2, "0");
      var mm = String(newdate.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = newdate.getFullYear();
      //var yy = yyyy.toString().substr(-2);
      //return dd + '.' + mm + '.' + yyyy;
      if (format === "dd.mm") {
        return dd + "." + mm;
      }
      if (format === "dd-mm-yyyy") {
        return dd + "-" + mm + "-" + yyyy;
      }
      if (format === "dd.mm.yyyy") {
        return dd + "." + mm + "." + yyyy;
      }
    }
  }
};
</script>
